import React from "react";
import {graphql} from 'gatsby';
import Img from 'gatsby-image';

// Components
import Layout from "../components/layout";
import SEO from "../components/seo";

class AboutPage extends React.Component {
  render() {
    const {data} = this.props;
    const {title, author} = data.site.siteMetadata;
    const profilePic = data.profilePic.childImageSharp.fluid;

    return (
      <Layout title={title}>
        <SEO title="About me" />
        <div className="aboutme-container">
          <section className="aboutme">
            <h2>About me</h2>
            <p>Hi, I'm Katie! 👋</p>
            <p>I'm a Data Lab MSc student in Artificial Intelligence at Heriot-Watt University 🤖</p>
            <p>Previously, I was a front end developer for 16 months 💻</p>
            <p>Before that, I was a bioinformatician for 4 years 📈</p>
            <p>I got my degree in Plant Science and my PhD in Plant Genomics/Bioinformatics 📜</p>
            <p>I live in Edinburgh with my fiancée and our cat, Dandelion 🐱</p>
            <p>I play the drums and I'm in a band 🥁</p>
            <p>I enjoy gaming, music and horror movies 👻</p>
          </section>
          <aside>
            <div style={{paddingTop: 36}}>
              <Img fluid={profilePic} alt={`Photo of ${author}`} />
            </div>
          </aside>
        </div>
      </Layout>
    )
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    profilePic: file(absolutePath: {regex: "/profilePic.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;